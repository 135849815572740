import { GetApiurl } from 'src/app/parameters';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { throwError, BehaviorSubject, Observable } from 'rxjs';
declare var $: any;

@Injectable()
export class GenericsService {
  private UserDetails = new BehaviorSubject<Array<any>>([]);
  User = this.UserDetails.asObservable() 

  constructor(private http: HttpClient) { }
  // check email exist on overall application
  // public get_lat_long(address) {
  //   let api = 'AIzaSyAT8k7s9wkpRBi4YiHNzi4WvFeRwZGOafM'

  //   const proxyurl = "https://cors-anywhere.herokuapp.com/";
  //   let url = "https://maps.googleapis.com/maps/api/geocode/json?address="+ address + "&key=" + api
  //   return this.http.get(proxyurl + url)
  //     .pipe(map(Response => Response), catchError(this.handleError))
  // }

  public get_lat_long(address) {
    let url = GetApiurl(`customer/get-latitude-langitude?address=${address}`)
    return this.http.get(url)
      .pipe(map(Response => Response), catchError(this.handleError))
  }
  public check_email_exist(data) {
    const url = GetApiurl('users/check_email_exist/');
    return this.http
      .post(url, JSON.stringify(data))
      .pipe(map(Response => Response), catchError(this.handleError));
  }
  // check mobile exist on overall application
  public check_mobile_exist(data) {
    const url = GetApiurl('users/check_mobile_exist/');
    return this.http
      .post(url, JSON.stringify(data))
      .pipe(map(Response => Response), catchError(this.handleError));
  }
  // create+\]oi vendor
  public create_vendor(data) {
    const url = GetApiurl('vendor/');
    return this.http
      .post(url, JSON.stringify(data))
      .pipe(map(Response => Response), catchError(this.handleError));
  }

  // create vendor from admin
  public create_vendor_admin(data) {
    let url = GetApiurl(`vendor/`)
    return new Promise((resolve, reject) => {
      let formData: FormData = new FormData();
      let xhr: XMLHttpRequest = new XMLHttpRequest();
      for (var i = 0; i < data['proof_details'].length; i++) {
        formData.append(data['proof_details'][i]['office_proofs'], data['proof_details'][i]['file_data'])
      }
      formData.append('data', JSON.stringify(data))
      formData.append('logo_path', data['logo_path'])
      formData.append('flag', 'web')
      xhr.open('POST', url, true);
      xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");
      xhr.setRequestHeader('Authorization', 'JWT ' + localStorage.getItem('jwt_token'));
      xhr.send(formData);
      xhr.onreadystatechange = function () {
        if (xhr.readyState == XMLHttpRequest.DONE) {
          resolve(JSON.parse(xhr.responseText));
        }
      }
    });
  }
  // create vendor
  public verify_vendor_otp(data) {
    const url = GetApiurl('vendor/verify-otp/');
    return this.http
      .post(url, JSON.stringify(data))
      .pipe(map(Response => Response), catchError(this.handleError));
  }
  // create vendor
  public resend_vendor_otp(data) {
    const url = GetApiurl('vendor/resend-otp/');
    return this.http
      .post(url, JSON.stringify(data))
      .pipe(map(Response => Response), catchError(this.handleError));
  }
  // Handeling error
  private handleError(error: Response | any) {
    let errMsg: string;
    let details;
    if (error instanceof Response) {
      const body = error.json() || "";
      const err = body["error"] || JSON.stringify(body);
      errMsg = `${error.status} - ${error.statusText || ""} ${err}`;
      details = { detail: body, status: err.status };
    } else {
      errMsg = error.message ? error.message : error.toString();
      details = error.error;
    }
    return throwError(details);
  }
  //users
  public getUserList(data) {
    this.UserDetails.next(data);
  }
  public getLogindetails(pk_id, role) {
    const url = GetApiurl(`users/get-user-data/${pk_id}/`)
    return this.http.get(url, {
      params: {
        'role': role
      }
    })
      .pipe(map(Response => {
        return Response
      }))
  }
  public getFrequency() {
    const url = GetApiurl('frequency/')
    return this.http.get(url)
      .pipe(map(Response => {
        return Response
      }))
  }
  public changePassword(value) {
    const url = GetApiurl('api-change-password/')
    return this.http.post(url, value)
      .pipe(map(Response => {
        return Response
      }))
  }
  public adminDashboardDetails() {
    const url = GetApiurl(`admin-dashboard/admin-dashboard/`)

    return this.http
      .get(url)
      .pipe(map(Response => Response), catchError(this.handleError));
  }

  public customerDashboardDetails(year?: any) {
    const url = GetApiurl(`admin-dashboard/customer-details/?year=${year}`)

    return this.http
      .get(url)
      .pipe(map(Response => Response), catchError(this.handleError));
  }

  public ticketDashboardDetails(year?: any) {
    const url = GetApiurl(`admin-dashboard/ticket-details/?year=${year}`)

    return this.http
      .get(url)
      .pipe(map(Response => Response), catchError(this.handleError));
  }

  public jobDashboardDetails(year?: any) {
    const url = GetApiurl(`admin-dashboard/job-details/?year=${year}`)

    return this.http
      .get(url)
      .pipe(map(Response => Response), catchError(this.handleError));
  }

  public vendorDashboardDetails(filter) {
    const url = GetApiurl(`admin-dashboard/vendor-details/?filter=${filter}`)

    return this.http
      .get(url)
      .pipe(map(Response => Response), catchError(this.handleError));
  }

  public getCustomerDashboardData(customer_id) {
    const url = GetApiurl(`customer/customer-dashboard-data/${customer_id}/`)
    return this.http.get(url)
      .pipe(map(Response => {
        return Response
      }))

  }

  public getCustomerInvoiceData(customer_id) {
    const url = GetApiurl(`customer-invoice/?customer=${customer_id}`)
    return this.http.get(url)
      .pipe(map(Response => {
        return Response
      }))
  }

  //Get GST for the mentioned HSN code
  public getGst() {
    const url = GetApiurl(`alserv-profile/get-gst/`)
    return this.http.get(url)
      .pipe(tap(response => {
        return response
      }))
  } 
}
