import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { GenericsService } from 'src/app/services/generics.service';
import { CustomerWalletService } from 'src/app/services';
import { getRazorpayId } from 'src/app/parameters';

declare var Razorpay: any;

@Component({
    selector: 'app-common-transaction-model',
    templateUrl: './common-transaction-model.component.html',
    styleUrls: ['./common-transaction-model.component.scss']
})
export class CommonTransactionModelComponent implements OnInit {

    showData: any;
    res: any;
    walletForm: FormGroup;
    customer_details: any;
    customerId: any;
    pay_id: any
    paymentDetails: any;
    disable_button: boolean = false;

    constructor(
        private dialogRef: MatDialogRef<CommonTransactionModelComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private fb: FormBuilder,
        private router: Router,
        private generalService: GenericsService,
        private walletService: CustomerWalletService
    ) {
        this.showData = data;
        this.customerId = this.showData['customerId']
        this.res = data['res'];
        this.buildForm()
    }

    ngOnInit() {
        this.generalService.getLogindetails(this.customerId, 'C')
            .subscribe(res => {
                if (res['records']) {
                    this.customer_details = res['records'];
                }
            })
    }

    recharge() {
        this.disable_button = true;
        let options: any = {
            "key": getRazorpayId.razorpay_id,
            "amount": '' + parseFloat(this.walletForm.value['total_amount']) * 100,
            "currency": "INR",
            "name": "Digiserv",
            "description": "Recharge for Digiserv",
            "prefill": {
                "name": this.customer_details['first_name'],
                "email": this.customer_details['email'],
                "contact": this.customer_details['phone']
            }
        };
        options.handler = ((response) => {
            this.pay_id = response.razorpay_payment_id;
            this.walletForm.controls['customer'].setValue(this.customerId)
            this.paymentDetails = {
                'customer': this.walletForm.value['customer'],
                'total_amount': this.walletForm.value['total_amount'], 'transaction_id': this.pay_id
            }
            this.walletService.savePaymentDetails(this.paymentDetails).subscribe(
                data => {
                    console.log(data);
                }
            );
            this.walletService.createPayment(this.walletForm.value).subscribe(
                data => {
                    console.log(data);
                    window.location.reload();
                    this.dialogRef.close(true);
                },
                error => {
                    console.log(error);
                    window.location.reload();
                    this.dialogRef.close(true);
                }
            );
        })
        var rzp1 = new Razorpay(options);
        rzp1.open();
    }

    buildForm() {
        this.walletForm = this.fb.group({
            'customer': [this.showData['customerId']],
            'total_amount': ['', Validators.required]
        });
        let diff = parseFloat(parseFloat((this.showData['totalWithDays'] - this.showData['ledger_amount']).toString()).toFixed(2));
        this.walletForm.controls['total_amount'].setValue(diff);
    }

    confirm() {
        this.router.navigate(['/app/service']);
    }

}
