// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  baseurl: 'https://dev-digiserv.starsystems.in/backend/',
  // baseurl: 'https://alserv-stagebck.starsystems.in/',
  // baseurl: 'https://stageportal.alserv.in/backend/',
  hmr: false
};

export const PUBNUBKEY = {
  publishKey: "pub-c-b39e9e0a-b9e8-4305-b935-3672f93629b1",
  subscribeKey: "sub-c-bdc59ba4-43c2-11e9-929f-425bf52815e4"
}

//Test id
export const RazorpayId = {
  razorpay_id: 'rzp_test_dn7jVSKeY9fyPM'
}


export const media_base_url = 'https://dev-digiserv.starsystems.in/'

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
